<template>

  <div>
    <!-- Title-->
    <textarea
        v-model="entityData.name"
        @input="adjustTextareaHeight"
        @change="editNewsletter()"
        @keydown.enter="editNewsletter()"
        ref="text"
        type="text"
        class="newsletter-name"
        :placeholder="$t('Enter newsletter name')"
        style="width: 100%; font-size: 22px; font-weight: 600; resize: none; outline: none;"
        rows="1"
    />
    <!-- Settings Accordion-->
    <div class="wsRoundedSemi overflow-hidden" :style="`border : 1px solid ${wsBACKGROUND}`">
      <div v-for="(item,i) in settingsSelect" :key="i"
           :style="i < (settingsSelect.length - 1) ? `border-bottom : 1px solid ${wsBACKGROUND}` : null"

      >
        <!-- Accordion Headings -->
        <v-hover #default="{hover}">
          <v-sheet :color="hover ? wsLIGHTCARD : 'transparent'  " class="d-flex align-center justify-space-between pa-4">
            <div class="d-flex align-center">
              <v-icon :color="item.isSetup ? wsSUCCESS : wsBACKGROUND" class="mr-4">mdi-check-circle</v-icon>
              <div>
                <h4 class="wsDARKER">
                  {{ item.name }}
                </h4>
                <h5 v-if="item.setup_pre_text" class="wsACCENT mb-1">
                  {{ item.setup_pre_text }}
                </h5>
                <h5 class="wsACCENT font-weight-regular">
                  {{ item.isSetup ? item.setup_text : item.subtitle }}
                </h5>
              </div>

            </div>

            <ws-button
                v-if="item.value !== 'sender'"
                @click="handleExpand(item.value)"
                style="border-radius: 100px"
                :label-raw="navigation === item.value ? $t('Hide') : (item.isSetup ? $t('Edit') : item.editText)"
                :color="wsATTENTION"
                text
            >
            </ws-button>
          </v-sheet>
        </v-hover>

        <!-- Content -->
        <v-expand-transition>
          <div :key="item.value" v-if="navigation === item.value"
               :style="`border-top : 1px solid ${wsBACKGROUND}`"
          >
            <div class="pa-6" >

              <!-- Receivers Single-->
              <div v-if="navigation === 'receivers'" >

                <email-receivers-group-dialog
                    @input="updateFilters"
                    @update-basic="entityData.basic_filters = $event"
                    :basic-filters="entityData.basic_filters"
                    v-model="entityData.filters"
                />

              </div>

              <!-- Sender -->
              <div v-if="navigation === 'sender'">
                Sender will be herere
              </div>

              <!-- Subject -->
              <div v-if="navigation === 'subject'">
                <ws-text-field
                    v-model="subject"
                    :label="$t('mailer.wizard.settings.subject.title')"
                    :placeholder="$t('mailer.wizard.settings.subject.placeholder')"
                    max-chars="150"
                />
                <ws-text-field
                    v-model="previewText"
                    :label="$t('mailer.wizard.settings.subject.preview')"
                    :placeholder="$t('mailer.wizard.settings.subject.preview_placeholder')"
                    class="mt-6"
                />
                <h5 class="wsACCENT font-weight-regular mt-3 mb-6">{{ $t('mailer.wizard.settings.subject.preview_description') }}</h5>
                <div class="d-flex justify-end">
                  <ws-button @click="saveSubject" :disabled="saveSubjectDisable" class="mt-6" label="Save" />
                </div>

              </div>

              <!-- Receivers Single-->
              <div v-if="navigation === 'time'">

                <v-row>
                  <v-col cols="6">

                    <ws-select
                        @input="handleDateChange"
                        v-if="sendingDate !== 'choose'"
                        :items="sendingDateSelect"
                        v-model="sendingDate"
                        :label="$t('mailer.wizard.settings.time.date_title')"
                        :clearable="!!sendingDate"
                        :error="dateError"
                    />
                    <ws-date-picker
                        v-else
                        v-model="sendingDateValue"
                        :label="$t('mailer.wizard.settings.time.date_title')"
                        :placeholder="$t('mailer.wizard.settings.time.date_placeholder')"
                        @clear="sendingDate = null"
                        :expand-trigger="datePickerTrigger"
                        clearable
                        :min="$time().YMD()"
                        :error="dateError"
                        @input="dateError = false"
                    />
                  </v-col>
                  <v-col cols="6" >
                    <portal-target name="wizzard-email-time"></portal-target>
                    <ws-select
                        v-if="!!sendingDate"
                        :items="timeSelect"
                        v-model="sendingTime"
                        :label="$t('mailer.wizard.settings.time.time_title')"
                        :placeholder="$t('mailer.wizard.settings.time.time_placeholder')"
                        :error="dateError"
                        @input="dateError = false"
                        autocomplete
                    />
                    <h5 v-if="sendingDateValue"
                        class="font-weight-regular wsDARKLIGHT mt-1 mb-3">
                      {{ $t('Timezone') }} :
                      <router-link
                          :to="businessDashLink('settings/general')"
                          class="noUnderline pointer linkHover wsATTENTION"
                          target="_blank"

                      >
                        <b class="">{{ $store.state.business.selectedBusiness.timezone }}</b>
                      </router-link>

                    </h5>

                  </v-col>
                </v-row>



                <div class="d-flex justify-end" >
                <portal to="wizzard-email-time" :disabled="!!sendingDateValue">

                    <ws-button @click="saveDate" :class="[{'mt-7' : !sendingDateValue}]"  label="Save" />

                </portal>
                </div>

              </div>

              <div v-if="navigation === 'after_action'">
                <ws-select
                    v-model="afterList"
                    :items="contactLists"
                    :label="$t('mailer.wizard.settings.after_action.list.label')"
                    :placeholder="$t('mailer.wizard.settings.after_action.list.placeholder')"
                    autocomplete
                    allow-new-data
                    hide-details
                    clearable
                />
                <ws-select
                    class="mt-5"
                    v-model="afterTag"
                    :label="$t('mailer.wizard.settings.after_action.tag.label')"
                    :placeholder="$t('mailer.wizard.settings.after_action.tag.placeholder')"
                    :items="tags"
                    autocomplete
                    allow-new-data
                    hide-details
                    clearable
                />

                <div class="d-flex justify-end mt-6" >
<!--                  <ws-button @click="cancelAfterAction" :color="wsACCENT" class="mr-2"  outlined  label="Cancel" />-->
                  <ws-button @click="saveAfterAction" :disabled="saveAfterActionDisableCondition"  label="Save" />
                </div>

              </div>

            </div>

          </div>
        </v-expand-transition>

      </div>
    </div>



  </div>




</template>

<script>
import {mapActions} from "vuex";
import emailReceiversGroupDialog
  from "@/modules/finemailer/components/newsletterWizard/emailSettingsAccordion/emailReceiversGroupDialog";
import finemailer from "@/modules/finemailer/mixins/finemailer";

export default {
  name: "emailSettingsAccordion",
  mixins : [finemailer],
  components : {
    emailReceiversGroupDialog
  },
  props : {
    items : {
      type : Array,
      default() { return [] }
    },
    newsletter : {
      type : Object,
      default() { return {} }
    },
  },
  data() {
    return {

      entityData : {},
      navigation : null,
      subject : null,
      previewText : null,
      sendingDate : null,
      sendingDateValue : null,
      sendingTime : null,
      datePickerTrigger : 0,


      displayEditReceivers : false,

      receiversEntityData : {},
      receiversHeaders : [{text : 'type' , value : 'type'}],
      subtractReceiverGroup : false,
      dateError : false,

      readyToUpdateFilters : false,

      contactLists : [],
      tags : [],

      afterList : null,
      afterTag : null,

    }
  },
  computed : {
    saveAfterActionDisableCondition() {
      return this.entityData.after_action_list_id === this.afterList && this.entityData.after_action_tag_id === this.afterTag
    },
    timeSelect() {
      if ( !this.sendingDateValue ) {
        return []
      }
      return this.$time(this.sendingDateValue).isToday
          ? this.GET_TIME_SELECT(30,true,2)
          : this.GET_TIME_SELECT(30,false)

    },
    sendingDateSelect() {
      return [
        { text : this.$t('mailer.wizard.settings.time.sendImmediately') , value : null },
        { text : this.$t('Today') , value : 'today' },
        { text : this.$t('Tomorrow') , value : 'tomorrow' },
        { text : this.$t('ChooseDate') , value : 'choose' }
      ]
    },
    saveSubjectDisable() {

      if ( this.subject !== this.entityData.subject) {
        return false
      }
      if ( this.previewText !== this.entityData.preview_text) {
        return false
      }

      return true
    },
    settingsSelect() {
      let items = [

        { name : this.$t('mailer.wizard.settings.receivers.title') ,
          subtitle : this.$t('mailer.wizard.settings.receivers.subtitle'),
          editText: this.$t('mailer.wizard.settings.receivers.add'),
          value : 'receivers',
          isSetup : this.entityData.filters.length > 0
        },
        { name : this.$t('From') ,
          subtitle : this.$t('mailer.wizard.settings.sender.subtitle'),
          editText: this.$t('Edit'),
          setup_pre_text : this.BUSINESS_EMAIL_CLIENT_ADDRESS ,
          setup_text : this.BUSINESS_NAME ,
          value : 'sender',
          isSetup : true
        },
        { name : this.$t('mailer.wizard.settings.subject.title') ,
          subtitle : this.$t('mailer.wizard.settings.subject.subtitle'),
          editText: this.$t('mailer.wizard.settings.subject.add'),
          value : 'subject',
          isSetup : !!this.entityData.subject,
          setup_pre_text : this.entityData.subject,
          setup_text : this.entityData.preview_text
        },
        { name : this.$t('mailer.wizard.settings.time.title') ,
          subtitle : this.$t('mailer.wizard.settings.time.subtitle'),
          editText: this.$t('mailer.wizard.settings.time.add'),
          value : 'time',
          isSetup : !!this.entityData.start_date,
          setup_text : this.$time(this.entityData.start_date).format("Today • time")
        },
        { name : this.$t('mailer.wizard.settings.after_action.title') ,
          subtitle : this.$t('mailer.wizard.settings.after_action.subtitle'),
          editText: this.$t('Edit'),
          value : 'after_action',
          isSetup : !!this.entityData.after_action_list_id || !!this.entityData.after_action_tag_id

        },

      ]

      return items
    }
  },
  watch :{
    navigation(value) {
      switch(value) {
        case 'after_action' : this.cancelAfterAction(); break
        case 'time' : this.handleEntityDate(); break;
      }
    }
  },
  methods : {
    ...mapActions('finemailer' , [
      'ADD_EDIT_NEWSLETTER',
      'GET_NEWSLETTER',
      'ADD_RECEIVERS_GROUP',
      'DELETE_RECEIVERS_GROUP'
    ]),
    ...mapActions('contacts' , [
      'GET_CONTACT_LISTS',
    ]),
    updateFilters() {
      if (!this.readyToUpdateFilters) {
        return
      }
      this.editNewsletter()
    },
    checkDateTextType() {

      if ( !this.sendingDateValue) {
        this.sendingDate = null;
      }

      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const date = new Date(`${this.sendingDateValue}T${this.sendingTime}:00Z`);
      date.setHours(0, 0, 0, 0);

      const dayDifference = (date.getTime() - today.getTime()) / (1000 * 60 * 60 * 24);

      if (dayDifference === 0) {
        this.sendingDate = 'today';
      } else if (dayDifference === 1) {
        this.sendingDate = 'tomorrow';
      } else {
        this.sendingDate = 'choose';
      }


    },
    saveDate() {
      const startDate = this.$time(`${this.sendingDateValue} ${this.sendingTime}`);

      if (startDate <= this.$time() ) {
        this.dateError = true;
        return this.ERROR('Time can not be earlier than now, do something with it')
      }

      let data = {...this.entityData}
      data.start_date = startDate.YMDT()
      this.editNewsletter(data)

    },

    handleDateChange(value) {
      this.dateError = false
      let date = new Date();

      if (!value) {
        this.sendingDateValue = null
      }
      if ( value === 'choose') {
        setTimeout(()=>{
          this.datePickerTrigger++
        },10)
      } if (value === 'today') {
        this.sendingDateValue =  `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
      } else if (value === 'tomorrow') {
        date.setDate(date.getDate() + 1);
        this.sendingDateValue = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
      }
    },
    saveSubject() {
      this.entityData.subject = this.subject
      this.entityData.preview_text = this.previewText
      this.entityData = this.COPY(this.entityData)
      this.editNewsletter()
    },
    saveAfterAction() {
      this.entityData.after_action_list_id = this.afterList
      this.entityData.after_action_tag_id = this.afterTag
      this.entityData = this.COPY(this.entityData)
      this.editNewsletter()
    },
    cancelAfterAction() {
      this.afterList = this.entityData.after_action_list_id
      this.afterTag = this.entityData.after_action_tag_id
    },
    async editNewsletter(data = null) {
      if (!data) {
        data = this.COPY(this.entityData)
      }
      let result = await this.ADD_EDIT_NEWSLETTER(data)
      if ( !result ) {
        return this.ERROR()
      }
      if ((this.entityData.after_action_list_id && this.entityData.after_action_list_id.includes('new-data::')) || (this.entityData.after_action_tag_id && this.entityData.after_action_tag_id.includes('new-data::'))) {
        await this.getListsAndTags()
      }
      this.entityData = result
      this.cancelAfterAction()
      this.$emit('update' , result)
    },

    // Technical
    getReceiverGroupName(type) {
      let item = this.RECEIVERS_GROUP_TYPES_SELECT.find(el => el.value === type)
      return item ? item.text : type
    },
    adjustTextareaHeight() {
      const input = this.$refs.text;
      input.style.height = "auto";
      if ( this.entityData.name ) {
        input.style.height = input.scrollHeight + "px";
      }
    },
    handleExpand(value) {
      this.navigation = this.navigation === value ? null : value
    },
    async getListsAndTags() {
      let result = await this.GET_CONTACT_LISTS()
      if (!result) {
        return
      }
      this.contactLists = result.lists
      this.tags = result.tags
    },
    handleEntityDate() {
      if ( this.entityData.start_date ) {
        let split = this.entityData.start_date.split(' ')
        this.sendingDateValue = split[0]
        this.sendingTime = split[1]
        this.checkDateTextType()
      } else {
        this.sendingDate = null
        this.sendingDateValue = null
        this.sendingTime = null
      }
    }
  },
  async mounted() {
    this.entityData = this.COPY(this.newsletter)
    this.subject = this.entityData.subject
    this.previewText = this.entityData.preview_text
    this.handleEntityDate()
    this.getListsAndTags()

    setTimeout(()=>{
      this.readyToUpdateFilters = true
    },500)
  }
}
</script>

<style scoped>
.newsletter-name {
  color : var(--wsDARKER) !important;
}
.newsletter-name::placeholder {
  color : var(--wsDARKLIGHT) !important;
}
</style>